import { useEffect, useRef } from "react";
import { SVG_PATH } from "../../../DataStore/ApiConstant";

const SingleBaccaratResultBoard = ({ Tblekey, result, index, baccaratResult }) => {
  const svgRef = useRef();

  useEffect(() => {
    const rows = 6;
    const patterns = result;

    const handleLoad = () => {
      const svgObj_mobile = svgRef.current?.contentDocument;
      if (!svgObj_mobile) {
        console.error("SVG contentDocument is not available.");
        return;
      }

      console.log("SVG contentDocument loaded successfully.");

      Object.keys(patterns).forEach(function (pattern) {
        if (
          pattern === "bigRoad" ||
          pattern === "bigEyeBoy" ||
          pattern === "smallRoad" ||
          pattern === "cockroach" ||
          pattern === "beadPlate"
        ) {
          patterns[pattern].forEach(function (res, k) {
            let cellCount = k + 1;
            res.forEach(function (j) {
              if (j !== "") {
                const element = svgObj_mobile.getElementById(`${pattern}_${cellCount}`);
                if (element) {
                  element.setAttribute("xlink:href", `#${pattern}_${j}`);
                } else {
                  console.warn(`Element ${pattern}_${cellCount} not found.`);
                }
              } else {
                const element = svgObj_mobile.getElementById(`${pattern}_${cellCount}`);
                if (element) {
                  element.setAttribute("xlink:href", `#none`);
                }
              }
              cellCount += rows;
            });
          });
        } else if (pattern === "predictResult") {
          const predictPattern = ["bigEyeBoy", "smallRoad", "cockroach"];
          patterns[pattern].forEach(function (results, i) {
            let objCopy = [...results];
            let bp = objCopy[0];
            objCopy.shift();
            objCopy.forEach(function (result, j) {
              if (result) {
                const predElement = svgObj_mobile.getElementById(`predictor_${bp}_${j}`);
                if (predElement) {
                  predElement.setAttribute("xlink:href", `#${predictPattern[j]}_${result}`);
                }
              }
            });
          });
        } else if (pattern === "resultCount") {
          let count = patterns[pattern];
          if (svgObj_mobile.getElementById("statictics-b")) svgObj_mobile.getElementById("statictics-b").textContent = count?.b || 0;
          if (svgObj_mobile.getElementById("statictics-p")) svgObj_mobile.getElementById("statictics-p").textContent = count?.p || 0;
          if (svgObj_mobile.getElementById("statictics-t")) svgObj_mobile.getElementById("statictics-t").textContent = count?.t || 0;
          if (svgObj_mobile.getElementById("statictics-bp")) svgObj_mobile.getElementById("statictics-bp").textContent = count?.bb || 0;
          if (svgObj_mobile.getElementById("statictics-pp")) svgObj_mobile.getElementById("statictics-pp").textContent = count?.pp || 0;
        }
      });
    };

    const waitForContentDocument = (callback, retries = 10, interval = 100) => {
      const checkContentDocument = () => {
        const svgObj = svgRef.current;
        if (svgObj && svgObj.contentDocument) {
          console.log("SVG contentDocument is now available.");
          callback();
        } else if (retries > 0) {
          console.warn(`Retrying to load SVG contentDocument... (${10 - retries + 1}/10)`);
          setTimeout(() => checkContentDocument(), interval);
          retries--;
        } else {
          console.error("SVG contentDocument not available after maximum retries.");
        }
      };
      checkContentDocument();
    };

    const svgElement = svgRef.current;
    if (!svgElement) {
      console.error("SVG element is not available.");
      return;
    }

    const handleLoadWrapper = () => {
      waitForContentDocument(handleLoad);
    };

    svgElement.addEventListener("load", handleLoadWrapper);

    return () => {
      svgElement.removeEventListener("load", handleLoadWrapper);
    };
  }, [result, baccaratResult]);

  return (
    <>
      <object
        key={"other" + index}
        className="single-baccarat-svg"
        ref={svgRef}
        type="image/svg+xml"
        data={SVG_PATH + "/mobile_bac_board.svg"}
      >
        {/* Fallback content can go here if needed */}
      </object>
    </>
  );
};

export default SingleBaccaratResultBoard;

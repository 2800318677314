import { IMAGE_BUCKET_URL, IMGBUCKETURL } from "../../DataStore/ApiConstant";
import { useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { connect } from "react-redux";
import TranslatesContent from "../../DataStore/Languages/translatesContent";
import { useRef } from "react";

const FeaturedGames = ({
  login,
  openPopup,
  siteImages,
  cashier = "false",
  setSelectedGameType,
  openGeneralModal,
  goToCashier
}) => {
  const history = useHistory();
  const gameWrapperArea = useRef(null);

  const featuredGameClicked = (type) => {
    if(cashier === "true"){
      history.push("/lobby")
      gameWrapperArea.current?.scrollIntoView({ behavior: "smooth" });
      setSelectedGameType(type)
    }else{
      gameWrapperArea.current?.scrollIntoView({ behavior: "smooth" });
      setSelectedGameType(type)
    }
    // cashier === "true" ? history.push("/lobby") : setSelectedGameType(type);
  };

  return (
    <div className="container-fluid mt-lg-3">
      <div className="row">
      <div
          className="col-3 col-sm-3 zoom disabled-div"
          // onClick={() => {login ? featuredGameClicked("loyalty") : openPopup("login")}}
          // onClick={() => featuredGameClicked("loyalty")}
          // onClick={() => alert("This is not reachable")}
          onClick={(e) => {
            e.preventDefault();
            login ? openGeneralModal("sportsModal") : openPopup("login");
            ;
          }}
        >
            <div className="row" data-aos="fade-up" data-aos-duration="3000">
              <div className="col">
              {
                Object.keys(siteImages.images).length > 0 &&
                <img
                  src={IMGBUCKETURL + "Sport_local.png"}
                  className="img-fluid mx-auto d-block"
                  alt="..."
                ></img>
              }
              </div>
              <p className="catTitel mt-0">
                {/* <TranslatesContent contentKey="sports" /> */}
                IPL BETTING
              </p>
            </div>
        </div>
        <div
          className="col-3 col-sm-3 zoom"
          onClick={() => featuredGameClicked("liveCasino")}
        >
          <div className="row" data-aos="fade-up" data-aos-duration="3000">
            <div className="col">
            {
              Object.keys(siteImages.images).length > 0 &&
              <img
                // src={IMGBUCKETURL + siteImages?.images?.main_tile_live_casino[0]}
                src={IMGBUCKETURL + "Live_casino_local.png"}
                className="img-fluid mx-auto d-block"
                alt="..."
              ></img>
            }
            </div>
            <p className="catTitel mt-0">
              <TranslatesContent contentKey="live-casino" />
            </p>
          </div>
        </div>
        {/* <div className="col-3 col-sm-3 zoom" onClick={() => featuredGameClicked("slots")}>
          <div className="row" data-aos="fade-up" data-aos-duration="3000">
            <div className="col"> */}
            {/* {
              Object.keys(siteImages.images).length > 0 &&
              <img
                src={IMGBUCKETURL + siteImages?.images?.main_tile_slots[0]}
                className="img-fluid mx-auto d-block"
                alt="..."
              ></img>
            } */}
              {/* <img
                src={IMAGE_BUCKET_URL + "slot.png"}
                className="img-fluid mx-auto d-block"
                alt="..."
              /> */}
            {/* </div>
            <p className="catTitel mt-0">
              <TranslatesContent contentKey="slots" />
            </p>
          </div>
        </div> */}
        
        <div
          className="col-3 col-sm-3 zoom"
          onClick={() => featuredGameClicked("events")}
        >
          <div className="row" data-aos="fade-up" data-aos-duration="3000">
            <div className="col">
            {
              Object.keys(siteImages.images).length > 0 &&
              <img
                // src={IMGBUCKETURL + siteImages?.images?.main_tile_slots[0]}
                src={IMGBUCKETURL + "Casino_event_local.png"}
                className="img-fluid mx-auto d-block"
                alt="..."
              ></img>
            }
              {/* <img
                src={IMAGE_BUCKET_URL + "slot.png"}
                className="img-fluid mx-auto d-block"
                alt="..."
              /> */}
            </div>
            <p className="catTitel mt-0">
              <TranslatesContent contentKey="slots" />
            </p>
          </div>
        </div>

        <div
          className="col-3 col-sm-3 zoom"
          onClick={() => featuredGameClicked("promotions")}
        >
          <div className="row" data-aos="fade-up" data-aos-duration="3000">
            <div className="col">
            {
              Object.keys(siteImages.images).length > 0 &&
              <img
                // src={IMGBUCKETURL + siteImages?.images?.main_tile_promotion[0]}
                src={IMGBUCKETURL + "Promotion_local.png"}
                className="img-fluid mx-auto d-block"
                alt="..."
              ></img>
            }
              {/* <img
                src={IMAGE_BUCKET_URL + "rummy.png"}
                className="img-fluid mx-auto d-block"
                alt="..."
              /> */}
            </div>
            <p className="catTitel mt-0">
              <TranslatesContent contentKey="promotions" />
            </p>
          </div>
        </div>
      </div>


      <div style={{scrollmargin: "400px"}} ref={gameWrapperArea}></div>
    </div>

    
  );
};


const setStatetoProps = (state) => {
  return {
    LanguageContent: state.SiteLanguages,
    siteImages : state.siteImages
  };
};

export default connect(setStatetoProps)(FeaturedGames);

// export default FeaturedGames;
